@import '@angular/material/theming';

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$custom-typography: mat-typography-config(
  $font-family: 'Open Sans, Helvetica, sans-serif',
  $body-1: mat-typography-level(18px, 24px, 500)
);

@include mat-core($custom-typography);

$mat-vesto-primary: (
  50: #e4f6ee,
  100: #7c9ef3,
  200: #6a8ce0,
  300: #6a8ce0,
  400: #6a8ce0,
  500: #7296f0,
  600: #6483d1,
  700: #5d7ac4,
  800: #5572b9,
  900: #3c5185,
  A100: #bdffd6,
  A200: #8affb6,
  A400: #57ff96,
  A700: #3dff87,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);
$mat-vesto-gold: (
  50: #fdf6e6,
  100: #fbe9bf,
  200: #f8db95,
  300: #f5cd6b,
  400: #f3c24b,
  500: #f1b72b,
  600: #efb026,
  700: #eda720,
  800: #eb9f1a,
  900: #e79010,
  A100: #ffffff,
  A200: #fff2e0,
  A400: #ffdbad,
  A700: #ffd094,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

$my-app-primary: mat-palette($mat-vesto-primary);
$my-app-accent: mat-palette($mat-vesto-gold);
$my-app-warn: mat-palette($mat-red);

$my-app-theme: mat-light-theme($my-app-primary, $my-app-accent, $my-app-warn);

@include angular-material-theme($my-app-theme);
@include angular-material-typography($custom-typography);

// Define an alternate dark theme.
$my-dark-primary: mat-palette($mat-grey, 50);
$my-dark-accent: mat-palette($mat-amber, A200, A100, A400);
$my-dark-warn: mat-palette($mat-deep-orange);

$my-dark-theme: mat-dark-theme($my-dark-primary, $my-dark-accent, $my-dark-warn);

@import '@vesto/xplat-scss/index';

// @import "~@ng-select/ng-select/themes/default.theme.css";
/**
 * The following are web specific (used with any web app targets)
 */

// web specific variables
@import 'variables';
@import 'tailwind';

// web styles (create/import other scss files or define as needed)
// @import 'spacing';
@import 'tags';
@import 'home';
@import 'form';
@import 'fonts';
@import 'modals';
@import 'header';
@import 'onfido';
@import 'dashboard';
@import 'blog';
@import 'webinars';
@import 'signup';
@import 'spinner';
@import 'white-paper';
@import 'animations';
