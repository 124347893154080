.animation-container {
  position: absolute;
  width: 200px;
  height: 100px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.5);
  -webkit-backface-visibility: hidden;
}

.blue-sprinkle-container {
  width: 50px;
  height: 100px;
  border-radius: 25px;
  position: absolute;
  left: 0;
  top: 0;
  transform: rotate(-45deg);
  transform-origin: 48% 26%;
  overflow: hidden;
}

.blue-sprinkle {
  width: 50px;
  height: 100px;
  border-radius: 25px;
  position: absolute;
  left: 0;
  top: 0;
  background: $gold;
  opacity: 0;
  animation: blue-sprinkle-loop 1.5s infinite;
}

.yellow-sprinkle-container {
  width: 50px;
  height: 150px;
  border-radius: 25px;
  position: absolute;
  left: 135px;
  top: 0;
  transform: rotate(45deg);
  transform-origin: 48% 26%;
  overflow: hidden;
}

.yellow-sprinkle {
  width: 50px;
  height: 150px;
  border-radius: 25px;
  position: absolute;
  left: 0;
  top: 0;
  background: $lilac;
  opacity: 0;
  animation: yellow-sprinkle-loop 1.5s infinite;
  animation-delay: 0.75s;
}

@keyframes blue-sprinkle-loop {
  0% {
    top: -50px;
    opacity: 0;
  }
  20% {
    top: -50px;
    opacity: 1;
  }
  40% {
    top: 0px;
    opacity: 1;
  }
  60% {
    top: 0px;
    opacity: 1;
  }
  80% {
    top: 50px;
    opacity: 1;
  }
  100% {
    top: 50px;
    opacity: 0;
  }
}

@keyframes blue-sprinkle-loop-smooth {
  0% {
    top: -50px;
    opacity: 0;
  }
  20% {
    top: -45px;
    opacity: 1;
  }
  80% {
    top: 0px;
    opacity: 1;
  }
  100% {
    top: 50px;
    opacity: 0;
  }
}

@keyframes yellow-sprinkle-loop {
  0% {
    top: 100px;
    opacity: 0;
  }
  20% {
    top: 100px;
    opacity: 1;
  }
  40% {
    top: 0px;
    opacity: 1;
  }
  60% {
    top: 0px;
    opacity: 1;
  }
  80% {
    top: -100px;
    opacity: 1;
  }
  100% {
    top: -100px;
    opacity: 0;
  }
}

@keyframes yellow-sprinkle-loop-smooth {
  0% {
    top: 100px;
    opacity: 0;
  }
  20% {
    top: 95px;
    opacity: 1;
  }
  80% {
    top: 0;
    opacity: 1;
  }
  100% {
    top: -100px;
    opacity: 0;
  }
}
