// .onfido-mount {
//   position: fixed;
//   top: 81px;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-color: #fff;
//   z-index: 100;
//   padding: 30px 0 0 0;
// }

.onfido-sdk-ui-Modal-overlay--after-open {
  z-index: 100;
}
