$lilac: #7B8CDE;
$primary: #000000;

body {
  color: $primary;
  font-family: 'Archivo', sans-serif;
  font-size: 16px;
  margin: 0;
  overflow-x: hidden;
}

h1, h2 {
  font-size: 44pt;
  font-weight: bold;

  @media (min-width: 768px) {
    line-height: 48pt;
  }
}

h3 {
  font-size: 32pt;
  font-weight: bold;
}

img,
svg {
  display: initial;
}

hr {
  display: inline-block;
}

a {
  text-decoration: none;
  color: $primary;
  cursor: pointer;
  line-height: initial;
  transition: all 330ms;

  &:hover {
    color: darken($primary-blue, 10%);
  }

  &.underline {
    text-decoration: underline !important;
  }

  &:focus {
    outline: none;
  }
}

.header {
  a:hover {
    font-weight: bold;
  }

  a::before {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    position: relative;
  }
}

.dark-mode {
  color: white;

  a {
    color: white;
    opacity: .8;

    &:hover {
      opacity: 1;
    }
  }
}

.spanlink {
  font-size: 16px;
  font-weight: bold;
  color: $link-blue;
  cursor: pointer;

  &.button-sm {
    font-size: 12px;
  }
}

.hero {
  background: url(/assets/images/hero-bg.svg) top left no-repeat;
  background-size: cover;
}

.tabs-container {
  background: #141414;
  // border-radius: 20px;
  max-width: 910px;

  .tab {
    cursor: pointer;

    &:hover {
      background: #1C1C1C;
    }

    &.active-tab {
      background: #1E1E1E;
      text-shadow: 0.33px 0 0 #FFF, -0.33px 0 0 #FFF;
    }
  }
}

.use-case-content {
  min-height: 630px;

  @media (min-width: 768px) {
    min-height: 290px;
  }
}

.big-fluffy-shadow {
  box-shadow: 0px 10px 40px 10px rgba(0, 0, 0, 0.05);
}

.rounded-20 {
  border-radius: 20px;
}

.rounded-60 {
  border-radius: 60px;
}

.md-rounded-60 {
  border-radius: 0;
  @media (min-width: 768px) {
    border-radius: 60px;
  }
}

.text-md {
  font-size: 1.25rem;
}

.text-gold {
  color: $gold;
}

.text-dark-gold {
  color: darken($gold, 7%);
}

.text-darker-gold {
  color: darken($gold, 15%);
}

.text-error {
  color: #842029;
}

.text-vesto-primary {
  color: $primary-blue;
}

.text-lilac {
  color: $lilac;
}

.text-link-blue {
  color: $link-blue;
}

.break-all {
  word-break: break-all;
}

.br-10 {
  border-radius: 10px;
}

.icon-button {
  background: transparent;
  border-radius: 50%;
  padding: 12px 10px;
  cursor: pointer;
  transition: all 330ms;

  &:hover {
    background: rgba(255, 255, 255, 0.5);
    transform: scale(1.1);
  }

  &:focus {
    outline: none;
  }
}

.button,
a.button {
  border: 1px solid transparent;
  padding: 16px 30px;
  border-radius: 100px;
  color: white;
  cursor: pointer;
  font-weight: bold;
  white-space: nowrap;
  display: inline-block;
  font-size: 16px;
  transition: all 0.3s;

  &:focus {
    outline: none;
  }

  &:hover {
    transform: scale(1.05, 1.05);
  }

  &:disabled {
    background-color: #999 !important;
    cursor: not-allowed !important;
  }

  &.blue-light {
    background-color: $light-blue !important;
    color: $primary-blue !important;

    &:hover {
      background-color: darken($light-blue, 5%) !important;
    }
  }

  &.button-primary {
    background: $primary;
  }

  &.button-lilac {
    background: $lilac;
  }

  &.button-white {
    background: #FFF;
    color: #000;
  }

  &.yellow {
    background-color: $gold !important;
    border-color: $gold !important;

    &:hover {
      border-color: darken($gold, 5%) !important;
      background-color: darken($gold, 5%) !important;
      transform: scale(1.1, 1.1);

      &:disabled {
        transform: scale(1);
      }
    }
    &:disabled {
      border-color: #999 !important;
      background-color: #999 !important;
    }
  }

  &.yellow-lighter {
    border-color: lighten($gold, 15%) !important;
    background-color: lighten($gold, 15%) !important;

    &:hover {
      background-color: darken($gold, 5%) !important;
      transform: scale(1.1, 1.1);
    }
  }

  &.outline-yellow {
    background-color: #fff;
    border: 1px solid $gold;
    color: $gold !important;

    &:hover {
      background-color: darken($gold, 5%) !important;
      transform: scale(1.1, 1.1);
      color: white !important;
    }
  }

  &.outline-lilac {
    background-color: transparent !important;
    border: 1px solid $lilac;
    color: $lilac !important;

    &:hover {
      background-color: darken($lilac, 5%) !important;
      transform: scale(1.05);
      color: white !important;

      &:disabled {
        background: transparent !important;
        transform: scale(1);
      }
    }

    &:disabled {
      color: #999 !important;
      border-color: #999 !important;
    }
  }

  &.outline-white {
    background-color: transparent !important;
    border: 1px solid #FFF;
    color: #FFF !important;

    &:hover {
      background-color: darken($lilac, 5%) !important;
      transform: scale(1.05);
      color: white !important;

      &:disabled {
        background: transparent !important;
        transform: scale(1);
      }
    }

    &:disabled {
      color: #999 !important;
      border-color: #999 !important;
    }
  }

  &.outline-blue {
    background-color: transparent !important;
    border: 1px solid $dark-blue;
    color: $dark-blue !important;

    &:hover {
      background-color: darken($dark-blue, 5%) !important;
      transform: scale(1.1, 1.1);
      color: white !important;

      &:disabled {
        background: transparent !important;
        transform: scale(1);
      }
    }

    &:disabled {
      color: #999 !important;
      border-color: #999 !important;
    }
  }

  &.outline-blue-light {
    background-color: transparent !important;
    border: 1px solid $soft-blue;
    color: $soft-blue !important;

    &:hover {
      background-color: darken($soft-blue, 5%) !important;
      transform: scale(1.1, 1.1);
      color: white !important;
    }
  }

  &.red {
    background-color: $red !important;

    &:hover {
      background-color: darken($red, 5%) !important;
      transform: scale(1.1, 1.1);
    }
  }

  &.gray {
    background-color: $gray !important;

    &:hover {
      background-color: darken($gray, 5%) !important;
      transform: scale(1.1, 1.1);
    }
  }

  &.default {
    color: rgb(60, 81, 133) !important;
  }

  &.button-large {
    font-size: 1.25rem !important;
    padding: 16px 40px;
  }

  &.button-sm {
    padding: 10px 15px;
  }

  &.button-xs {
    font-size: 10px;
    padding: 4px 8px;
  }

  &:hover {
    text-decoration: none;
  }
}

.mat-dialog-content .button:hover {
  // transform: scale(1);
}

.bg-light-blue {
  background: $light-blue;
}

.bg-lighter-blue {
  background: $lighter-blue;
}

.bg-soft-blue {
  background: $soft-blue;
}

.bg-trans-blue {
  background: $trans-blue;
}

.bg-ethereum {
  background-color: #f3f3f3;
}

.bg-polygon {
  background-color: #f1edfb;
}

.bg-polygon-dark {
  background-color: #e1d6fb;
}

.bg-light-gold {
  background-color: lighten($gold, 35%);
}

.bg-blue-gradient {
  background: rgb(94, 126, 191);
  background: linear-gradient(90deg, rgba(94, 126, 191, 1) 0%, rgba(79, 99, 146, 1) 100%);
}

.bg-dark-grey {
  background: #1E1E1E;
}

.bg-light-gray {
  background: #F4F4F4;
}

.bg-lilac {
  background: $lilac;
}

.bg-lilac-light {
  background: lighten($lilac, 25%);
}

.bg-lilac-icon {
  background: url(/assets/images/lilac-icon-bg.svg) top left no-repeat #7B8CDE;
  background-size: cover;
}

.shadowed {
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
}

.filter-gray {
  filter: grayscale(100%);
  opacity: .66;
}

.filter-white-transparent {
  filter: brightness(0) invert(1);
  opacity: .66;
}

.filter-white {
  filter: brightness(0) invert(1);
}

.filter-black {
  filter: brightness(0);
  opacity: .66;
}

.separator {
  max-width: 300px;
  width: 100%;
  border-bottom: 3px solid $gold;
}

.product {
  padding: 20px;
  border: 1px solid #d1e1f2;
  border-radius: 10px;
  width: 28%;

  @media (max-width: 768px) {
    width: 100%;
  }

  div {
    min-height: 90px;
    border-bottom: 1px solid #e1e4ed;
  }
}

.price-compare-container {
  background: rgb(235, 241, 249);
  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 50%, rgba(235, 241, 249, 1) 50%);

  .price-compare {
    background-image: linear-gradient(90deg, #4f6291, #5d7dbe 90%);
    padding: 40px 60px;

    @media (max-width: 768px) {
      padding: 20px 30px;
    }

    &.left {
      border-radius: 50px 10px 90px 30px;

      @media (max-width: 768px) {
        border-radius: 100px;
      }
    }

    &.right {
      border-radius: 10px 50px 30px 90px;

      @media (max-width: 768px) {
        border-radius: 100px;
      }
    }

    .number {
      color: #ffc107;
      font-size: 40px;
      font-weight: bold;

      @media (max-width: 768px) {
        font-size: 20px;
      }
    }

    .title {
      color: white;
      font-size: 22px;
      font-weight: bold;

      @media (max-width: 768px) {
        font-size: 15px;
      }
    }
  }

  .price-compare-equals {
    font-size: 70px;
    padding: 0px 60px;
    font-weight: bold;

    @media (max-width: 768px) {
      padding: 0px 20px;
    }
  }
}

.row-odd {
  position: relative;
  background-color: rgba(235, 241, 249, 1);
}

.icon-list li {
  font-size: 18px;
  font-weight: bold;
  list-style: none;
  padding: 20px 10px;
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background-size: 20px 20px;
    background-repeat: no-repeat;
    position: absolute;
    top: 22px;
    left: -20px;
  }

  &:nth-child(1)::before {
    background-image: url('/assets/icons/clock.svg');
  }
  &:nth-child(2)::before {
    background-image: url('/assets/icons/lineup.svg');
  }
  &:nth-child(3)::before {
    background-image: url('/assets/icons/fire.svg');
  }
}

.round-box {
  background-color: white;
  padding: 20px;
  border: 1px solid #e1e4ed;
  border-radius: 10px;
}

.article-snippet {
  .snippet-container {
    cursor: pointer;
    background: #141414;
    min-width: 30%;
    transition: all 300ms;
    padding-bottom: 90px !important;

    @media (max-width: 768px) {
      min-width: 100%;
    }

    &:hover {
      background: #1C1C1C;
      transform: scale(1.02);
    }

    img {
      width: 50px;
      height: 50px;
      border-radius: 25px;
    }
  }
  .spacer {
    width: 2%;

    @media (max-width: 768px) {
      width: 0%;
    }
  }
}

.video-bg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  color: #fff;

  div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 900px;
  }
}

.play-button {
  position: absolute;
  top: 100px;
  left: 42%;
  width: 70px;
  z-index: 3;
}

.play-button-bg {
  background: $gold;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 35px);
  left: calc(50% - 35px);
  z-index: 2;

  @media (min-width: 768px) {
    top: 100px;
    left: 42%;
  }
}

.blue-token {
  width: 36px;
  position: absolute;
  left: 12px;
  top: 8px;

  @media (min-width: 768px) {
    width: 24px;
    left: 6px;
    top: 13px;
  }

  @media (min-width: 992px) {
    width: 36px;
    position: absolute;
    left: 5px;
    top: 5px;
  }

  @media (min-width: 1200px){
    width: 36px;
    left: 11px;
    top: 8px;
  }
}

.yellow-token {
  width: 36px;
  position: absolute;
  left: 48px;
  top: 33px;

  @media (min-width: 768px) {
    width: 24px;
    left: 27px;
    top: 27px;
  }

  @media (min-width: 992px) {
    width: 36px;
    left: 33px;
    top: 26px;
  }

  @media (min-width: 1200px){
    width: 36px;
    left: 45px;
    top: 33px;
  }
}

.multi-chain-icon {
  width: 36px;
  left: 47%;
  top: 17%;

  @media (min-width: 768px) {
    left: 49%;
    top: 16%;
  }
}

.border-bottom-light {
  border-bottom: 1px solid #c9d1e0;
}

.border-top-light {
  border-top: 1px solid #c9d1e0;
}

.border-right-light {
  border-right: 1px solid #c9d1e0;
}

.vertical-separator {
  background: #c9d1e0;
  width: 1px;
}

.carousel-cell {
  padding: 20px;
}

.onfido-sdk-ui-Button-button-primary {
  background-color: $gold !important;
  border-radius: 10px !important;
  transition: all 300ms;

  &:hover {
    background-color: darken($gold, 5%) !important;
    transform: scale(1.05);
  }
}

.onfido-sdk-ui-Button-button:disabled {
  color: #fff !important;
  border-radius: 10px !important;
}

.onfido-sdk-ui-DocumentSelector-option.onfido-sdk-ui-DocumentSelector-optionHoverDesktop:hover {
  box-shadow: 0 0 0 2px $primary-blue !important;
}

.mat-menu-item {
  font-family: 'Archivo', sans-serif !important;
}

.mat-snack-bar-container {
  background: $light-blue !important;
  color: $primary-blue !important;
  z-index: 1001 !important;

  .mat-simple-snackbar-action button {
    background: $trans-blue !important;
    color: $primary-blue !important;
  }
}

.cdk-overlay-backdrop {
  z-index: 1 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

// temp grid styles
.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.gap-2 {
  gap: 0.5rem;
}

.gap-4 {
  gap: 1rem;
}

.grid {
  display: grid;
  grid-auto-rows: 1fr;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.col-span-4 {
  grid-column: span 4 / span 4;
}
