.slide-in {
  animation-name: slide-in;
  animation-duration: 330ms;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes slide-in {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-in-left {
  animation-name: slide-in-left;
  animation-duration: 330ms;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes slide-in-left {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-in-right {
  animation-name: slide-in-right;
  animation-duration: 330ms;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes slide-in-right {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-out-left {
  animation-name: slide-out-left;
  animation-duration: 330ms;
  animation-fill-mode: forwards;
  opacity: 1;
}

@keyframes slide-out-left {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-100px);
    opacity: 0;
  }
}

.slide-out-right {
  animation-name: slide-out-right;
  animation-duration: 330ms;
  animation-fill-mode: forwards;
  opacity: 1;
}

@keyframes slide-out-right {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(100px);
    opacity: 0;
  }
}

.slide-in-down {
  animation-name: slide-in-down;
  animation-duration: 330ms;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes slide-in-down {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.bg-flash {
  animation: bg-flash 1000ms infinite;
}

@keyframes bg-flash {
  0% {
    background-color: #eeeeee;
  }
  50% {
    background-color: #dddddd;
  }
  100% {
    background-color: #eeeeee;
  }
}

.pulse-scale {
  animation: pulse infinite 4000ms ease-in-out;
}

@keyframes pulse {
  10% {
    transform: scale(1.1);
  }
  20%, 100% {
    transform: scale(1);
  }
}

.pulse-and-fade {
  opacity: .75;
  animation: pulse-and-fade infinite 4000ms ease-out;
  animation-delay: 500ms;
}

@keyframes pulse-and-fade {
  10% {
    transform: scale(1.2);
    opacity: .33;
  }
  20%, 100% {
    transform: scale(1.2);
    opacity: 0;
  }
}

.bounce {
  animation-name: bounce;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes bounce {
  8% {
    transform: translateY(-10px);
  }
  16%, 100% {
    transform: translateY(0);
  }
}

.flip {
  animation-name: flip;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes flip {
  10% {
    transform: rotateY(180deg);
  }
  20%, 100% {
    transform: rotateY(0deg);
  }
}

.rotate-180 {
  animation: rotate-180 330ms forwards ease-in-out;
}

@keyframes rotate-180 {
  100% {
    transform: rotate(180deg);
  }
}

.text-grow {

}
