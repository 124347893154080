/**
 * Element tag overrides
 */
body {
  padding: 0;
  margin: 0;
}

.floating {
  transition: background-color 330ms;
  background-color: rgba(255, 255, 255, 0.95);
}

.scroll-target {
  position: absolute;
  top: -90px;
}

.dark-mode.floating {
  transition: background-color 330ms;
  background-color: $primary-blue;
}

.transition-all {
  transition: all 330ms;
}

.hover-translate-y:hover {
  transform: translateY(-10px);
}

.hover-bold-black:hover {
  text-shadow: 0.33px 0 0 #000, -0.33px 0 0 #000;
}

gbl-accounts,
gbl-payment-methods,
gbl-settings,
gbl-admin-alerts,
gbl-admin-user {
  display: block;
  width: 100%;
  // padding: 10px;
  // display: flex;
  // justify-content: space-between;
}

.overflow-dialog .mat-dialog-container, .overflow-dialog .mat-dialog-content {
  overflow: visible !important;
}
