.top-links {
  // justify-content: space-around;
  align-items: center;
  // text-align: center;

  a {
    margin: 0 15px;
    position: relative;

    &::after {
      content: "";
      background: $lilac;
      //border-bottom: 2px solid gold;
      height: 0;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: -10px;
      transition: all 330ms;
    }

    &:hover {
      font-weight: normal;

      &::after {
        height: 2px;
      }
    }

    &.selected {
      &:hover {
        font-weight: bold;
      }
      &::after {
        content: "";
        border-bottom: 2px solid $lilac;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: -10px;
      }
    }
  }

  .profile {
    position: relative;

    img {
      width: 30px;
      height: 30px;
      border-radius: 15px;
      margin-right: 15px;
      cursor: pointer;
    }

    i {
      cursor: pointer;
    }
  }
}

.dropdown {
  position: absolute;
  right: 10px;
  left: 10px;
  top: 60px;
  box-shadow: 1px 1px 3px rgb(0 0 0 / 33%);
  border-radius: 10px;
  padding: 10px 20px;
  white-space: nowrap;

  .dropdown-option {
    margin: 20px 10px;
  }
}

.dark-mode .dropdown {
  background: $primary-blue;
}

.mat-drawer-container {
  // overflow: initial !important;
  z-index: 1000 !important;

  .mat-drawer-backdrop {
    height: 100vh;
  }

  .mat-drawer {
    color: $primary-blue;
    height: 100vh;
    width: 300px;

    button.close {
      top: 2px;
      right: 10px;
    }
  }
}

.mat-drawer-container-has-open {
  overflow: initial !important;
}

.notification-item + .notification-item {
  border-top: 1px solid #c9d1e0;
}

@keyframes border-grow {
  0% {
    border-bottom-width: 0;
  }
  100% {
    border-bottom-width: 2px;
  }
}
