.vesto-input {
  position: relative;

  label {
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 6px;
  }

  input,
  select,
  textarea,
  .ng-select-container {
    padding: 15px;
    background-color: rgba(0, 0, 0, 0.02);
    border-radius: 12px;
    color: $primary-blue;
    font-size: 15px;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.1);
    transition: all 0.5s ease-in-out;
    width: 100%;

    &[disabled] {
      background-color: rgba(0, 0, 0, 0.1);
    }

    &.dirty,
    &:focus {
      border: 1px solid $lilac;
    }
  }

  &.vesto-select,
  &.vesto-country-select {
    input,
    select,
    textarea,
    .ng-select-container {
      color: transparent;
      text-shadow: 0 0 0 $primary-blue;
    }

    &.vesto-select-small {
      .ng-select-container {
        font-size: 0.75rem;
        padding: 4px 0;
        border-radius: 5px;
      }

      &.vesto-select-small-placeholder .ng-placeholder {
        margin: 0 6px;
      }
    }
  }

  .ng-select.ng-select-opened .ng-select-container {
    border-color: $lilac;
  }

  .ng-select .ng-arrow-wrapper {
    width: 25px;
    padding-right: 5px;
    cursor: pointer;
    position: relative;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .ng-select .ng-arrow-wrapper .ng-arrow {
    border-color: #999999 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 2.5px;
    pointer-events: none;
    display: inline-block;
    height: 0;
    width: 0;
    position: relative;
  }

  .ng-placeholder {
    margin-right: 16px;
    opacity: 0.5;
  }

  .ng-dropdown-panel {
    background-color: #FFF;
    border: 1px solid $lilac;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    left: 0;
    font-size: 15px;
  }

  ng-dropdown-panel.ng-select-bottom {
    top: 100%;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    border-top-color: #e6e5e5;
    margin-top: -12px;
  }

  .ng-option {
    padding: 12px;
    transition: background-color 330ms;

    &.ng-option-selected {
      background-color: $trans-blue;
      font-weight: bold;
    }

    &:hover {
      background-color: $light-blue;
    }
  }

  .form-error {
    color: red;
    font-size: 14px;
    font-weight: bold;
    margin-top: 12px;
    display: block;
    text-indent: 12px;
  }

  .mat-slider-horizontal {
    width: 100%;
  }

  &.vesto-input-inline {
    label {
      color: #b8c2cc;
      position: absolute;
      top: 15px;
      left: 10px;
      font-weight: normal;
    }

    input,
    select,
    textarea {
      padding: 15px 30px 15px 100px;
      text-align: right;
    }

    .mat-datepicker-toggle {
      top: 3px;
    }
  }

  .vesto-country-select {
    position: absolute;
    top: 33px;

    .ng-select-container {
      background: none;
      border: none;
      padding: 10px;
    }

    .ng-placeholder {
      margin: 0;
    }

    .ng-dropdown-panel {
      width: 300px;
    }
  }

  .show-hide-password {
    position: absolute;
    right: 10px;
    top: 46px;
    font-size: 18px;
  }

  & .c-white {
    label {
      color: white;
    }
    input {
      background-color: white;
    }
  }
}

.form-dark {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: #FFF;
    -webkit-box-shadow: 0 0 0px 1000px #333 inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  .vesto-input {
    input,
    select,
    textarea,
    .ng-select-container {
      background-color: #333 !important;
      color: #FFF !important;
    }
  }
}
.gas-tank-modal .vesto-input-inline input {
  padding-left: 150px;
}

.vesto-currency-input {
  background: transparent;
  font-size: 64px;
  color: $primary-blue;
  width: 100%;
  text-align: center;

  &:focus {
    outline: none;
  }
}

.intl-tel-input {
  width: 100%;
}

.iti {
  width: 100%;
}

.currency-input {
  label {
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 6px;
  }
}
.vesto-alert {
  border-radius: 10px;
  animation: fade-in 500ms forwards;
}

.mat-datepicker-toggle {
  color: #3c5185 !important;
  position: absolute;
  right: 1px;
  top: 39px;
}

.mat-slide-toggle-content {
  font-family: 'Lato', sans-serif;
  font-size: 1rem;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.vesto-input-dark .ng-dropdown-panel {
  background-color: #000;
}
