.dashboard-page {
  background: lighten($light-blue, 2%);
}

.dashboard-header {
  height: 100px;
  position: sticky;
  top: 0;
  z-index: 1;
}

.dashboard-content {
  background: #F6F6F6;
  width: 100%;
  height: 100vh;
  overflow: auto;

  .container {
    height: calc(100vh - 75px);
  }
}

.sidenav {
  background: #FFF;
  height: 100vh;
  width: 300px;
  position: relative;

  .sidenav-header {
    height: 100px;
  }

  .sidenav-body {
    overflow: auto;
    max-height: calc(100vh - 125px);
  }

  .sidenav-item {
    display: block;
    padding: 18px 24px;
    border-radius: 60px;
    color: #999;
    margin-bottom: 10px;
    position: relative;

    &.selected {
      background: $lilac;
      color: #FFF;
      font-weight: bold;
    }

    &:hover {
      text-decoration: none;
    }
  }

  .sidenav-panel {
    background: #dae1e7;
    //margin-top: -40px;
    //padding-top: 40px;
    border-radius: 30px;
    // padding-bottom: 30px;

    a {
      display: block;
      font-size: 18px;
      padding: 12px 12px 12px 48px;

      &:hover {
        background: rgba(255, 255, 255, 0.2);
        text-decoration: none;
      }

      &.selected {
        background: rgba(255, 255, 255, 0.1);
        font-weight: bold;
      }
    }

    .sidenav-subitem {
      padding: 12px 24px;
      font-size: 18px;

      &:hover {
        background: rgba(255, 255, 255, 0.5);
        text-decoration: none;
      }

      &.selected {
        background: rgba(255, 255, 255, 0.33);
        font-weight: bold;
      }
    }
  }
}

.notifications-header {
  background: darken($light-blue, 15%);
  height: 74px;
}
.accounts {
  .filter-button {
    background: lighten($lilac, 25%);
    cursor: pointer;
    padding: 8px 12px;
    border-radius: 60px;
    min-width: 170px;
    min-height: 48px;
    position: relative;

    img {
      opacity: 0.5;
    }

    &.filter-button-no-meta::before {
      display: none;
    }

    &:hover,
    &.selected {
      background: $lilac;
      color: #FFF;

      img {
        opacity: 1;
      }
    }

    &.selected {
      font-weight: bold;
    }

    &.no-icon::before {
      display: none;
    }
  }

  .filter-button-light {
    background: darken($trans-blue, 5%);
    border: 1px solid darken($trans-blue, 5%);
    cursor: pointer;
    padding: 8px 12px;
    border-radius: 60px;
    min-width: 100px;
    text-align: center;
    font-size: 14px;
    color: $soft-blue;

    &:hover,
    &.selected {
      background: #fff;
      border-color: $primary-blue;
      color: $primary-blue;
    }

    &.selected {
      font-weight: bold;
    }
  }

  .chart-type-button {
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
    opacity: 0.5;
    font-size: 12px;

    &.selected {
      opacity: 1;
    }
  }

  .accounts-selector {
    background-color: transparent;
    padding: 10px 24px;
    border: 1px solid #c9d1e0;
    cursor: pointer;
    border-bottom: none;
    font-size: 1.05rem;
    min-height: 48px;

    &:hover {
      background-color: #dde7f998;
      cursor: initial;
    }

    &.mat-expanded:hover {
      background-color: transparent;
    }

    &.selected {
      border-left: 3px solid $gold;
      padding-left: 22px;
    }

    &:last-child {
      border-bottom: 1px solid #c9d1e0;
    }
  }

  .transaction-item {
    background-color: lighten($lilac, 30%);
    cursor: pointer;
    padding: 20px;

    &:hover {
      background-color: lighten($lilac, 25%);
    }

    & + .transaction-item {
      margin-top: 10px;
    }

    .deposit-created {
      font-size: 12px;
      font-weight: bold;
    }
  }

  .account-summary {
    margin: 10px 0px;
  }
}

img.ethereum {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.small-note {
  max-width: 400px;
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
  margin-top: -40px;
}

.list-item {
  display: flex;
  align-items: center;
  padding: 20px;
  margin-bottom: 10px;
  background-color: #e6ecf5;
  transition: all 0.3s !important;

  &.selectable {
    cursor: pointer;

    &:hover {
      transform: scale(1.03, 1.03);
    }
  }
}

.circle-icon-button {
  border-radius: 50%;
  cursor: pointer;
  text-align: center;
  transition: background-color 330ms;
  padding: 10px;
  width: 44px;

  &:hover {
    background-color: lighten($lilac, 25%);
  }
}

.circle-icon-button-color {
  border-radius: 50%;
  transition: all 330ms;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    transform: scale(1.1);
  }
}

.icon-button-small {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  transition: background-color 330ms;

  &:hover {
    background-color: $trans-blue;
  }
}

.qrcode {
  text-align: center;
}

.choose-linked-account-option {
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #c8d1e2;

  &:hover {
    background-color: #c8d1e2;
  }
}

.switcher {
  .option {
    border: 1px solid #c8d1e2;
    border-radius: 10px;
    cursor: pointer;

    &.selected {
      background-color: $dark-blue;
      color: white;
    }
  }
}

.alert-item {
  width: 100%;
  border-bottom: 1px solid $light-blue;
}

.alerts-container {
  background-color: #f9e6d0;
  padding: 10px;
  margin-right: 10px;
}

.status-pill {
  font-size: 11px;
  padding: 0px 5px;
  border-radius: 10px;
  color: white;
  background-color: green;
  line-height: 26px;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s !important;

  &.pending {
    background-color: #ffc107;
  }

  &.suspended {
    background-color: $red;
  }

  &.active {
    background-color: $green;
  }
}

.joint-user-list-item {
  padding: 5px;
  border-bottom: 1px solid #c8d1e2;

  &:hover {
    background-color: #c8d1e2;
  }
}

.tabber {
  display: flex;
  margin: auto;
  width: 100%;
  border: 2px solid #ffc107;
  border-radius: 10px;

  .option {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    text-align: center;
    font-weight: bold;
    cursor: pointer;

    &.selected {
      color: white;
      background-color: #ffc107;
    }
  }
}

.bottom-line {
  border-bottom: 1px solid #c8d1e2;
}

#pagingContainer {
  background-color: #ecf1fa;
  padding: 10px;
  border-radius: 10px;
  bottom: 10px;
}

.account-buttons {
  // background: #eee;
  border-top: 1px solid $light-blue;
  margin: 0 -24px -16px;
  padding: 1px 24px 8px;
}

.mat-expansion-panel {
  background: none !important;
  border-radius: 0 !important;
  box-shadow: none !important;

  .mat-expansion-panel-header,
  .mat-expansion-panel-content {
    font-family: 'Archive', sans-serif !important;
  }

  .mat-expansion-panel-header {
    border-top: 1px solid #666;
    font-size: 1.05rem;
    height: auto !important;
    padding: 12px !important;

    .mat-expansion-panel-header-title {
      font-weight: bold;
    }
  }

  .mat-expansion-panel-body {
    background: rgba(255, 255, 255, 0.1);
  }

  .mat-expansion-indicator::after {
    border-color: $lilac;
  }
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled='true']),
.mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled='true']),
.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled='true']) {
  background: none !important;
}

.mat-paginator-container {
  justify-content: flex-start !important;
}

.asset-row {
  border-left: 3px solid transparent;
  cursor: pointer;
  padding: 8px;
  transition: all 300ms;
  font-size: 14px;

  &:hover {
    background: $light-blue;
  }

  div:first-child {
    font-weight: bold;
  }

  &.active {
    background: #dde7f998;
    // border-left-color: $dark-blue;
  }
}

.ngx-charts-outer text {
  font-size: 16px;
  color: $primary-blue;
}

.tab-header-container {
  background: lighten($lilac, 25%);
  margin: -48px -48px 0 -48px;

  &.tabs-small {
    margin: 0 -16px;

    .tab-header {
      padding: 12px;

      &.tab-header-active {
        border-top: 1px solid $soft-blue;
      }
    }
  }

  .tab-header {
    border-bottom: 1px solid $soft-blue;
    padding: 24px;
    text-align: center;
    color: $soft-blue;
    transition: all 300ms;

    & + .tab-header {
      border-left: 1px solid $soft-blue;
    }

    &.tab-header-active {
      border-bottom-color: #fff;
      background: #fff;
      font-weight: bold;
      color: $primary-blue;
    }
  }
}

.error-icon {
  background: #f8d7da;
  border-radius: 50%;
  color: #842029;
  padding: 5px;
}

.admin-buttons .button {
  width: 130px;
}

text {
  text-transform: uppercase;
}

.highcharts-label.highcharts-no-data {
  width: 100%;
  height: 100%;
  top: 0 !important;
  left: 0 !important;

  &::before {
    content: '';
    position: absolute;
    background: url(/assets/images/chart-placeholder.svg) center center repeat-x;
    top: 0;
    right: 10px;
    bottom: 13px;
    left: 10px;
    opacity: 0.4;
  }
}

.mat-drawer {
  top: -36px !important;
  right: -16px !important;
}

.currency-item + .currency-item {
  border-left: 1px solid #c9d1e0;
  padding-left: 1.5rem;
}

.system-component {
  background-color: white;
  border: 1px solid transparent;
  cursor: pointer;
  transition: all 330ms;
  min-height: 220.5px;

  &:hover {
    background-color: white;
  }

  &.system-component-selected {
    background-color: white;
    border-color: $gold;
  }
}

.placeholder-short {
  width: 200px;
  height: 16px;
  border-radius: 16px;
}

.placeholder-long {
  width: 300px;
  height: 16px;
  border-radius: 16px;
}

.placeholder-area {
  width: 100%;
  height: 150px;
  border-radius: 16px;
}

.embolden {
  animation: embolden 300ms forwards;
}

.fade-out-up {
  animation: fade-out-up 300ms forwards;
}

.fade-in-up {
  opacity: 0;
  animation: fade-in-up 300ms forwards;
}

.text-grey-to-primary {
  color: #b8c2cc;
  animation: text-grey-to-primary 300ms forwards;
}

@keyframes embolden {
  0% {
    font-weight: normal;
  }
  100% {
    font-weight: bold;
  }
}

@keyframes fade-out-up {
  0% {
    opacity: 1;
    margin-top: 0;
  }
  100% {
    opacity: 0;
    margin-top: -30px;
  }
}

@keyframes fade-in-up {
  0% {
    opacity: 0;
    margin-bottom: -30px;
  }
  100% {
    opacity: 1;
    margin-top: 0px;
  }
}

@keyframes text-grey-to-primary {
  0% {
    color: #b8c2cc;
  }
  100% {
    color: $primary-blue;
  }
}

.delay-300 {
  animation-delay: 300ms;
}

.delay-600 {
  animation-delay: 600ms;
}

.delay-900 {
  animation-delay: 900ms;
}

.delay-1000 {
  animation-delay: 1000ms !important;
}

.delay-1200 {
  animation-delay: 1200ms;
}

.delay-1500 {
  animation-delay: 1500ms;
}

.delay-1800 {
  animation-delay: 1800ms;
}

.delay-2100 {
  animation-delay: 2100ms;
}
