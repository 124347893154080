.white-paper {
  section {
    margin: 42px 0;
  }
  h2 {
    margin-bottom: 18px;
  }
  h3 {
    font-size: 1.5rem;
  }
  p {
    font-size: 1.2rem;
    margin-bottom: 18px;
  }
  ul {
    font-size: 1.2rem;
    padding-left: 1rem;
    list-style: none; /* Remove default bullets */
  }
  ul li::before {
    content: '\2022'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: $gold; /* Change the color */
    //font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1em; /* Also needed for space (tweak if needed) */
    margin-left: -1em; /* Also needed for space (tweak if needed) */
  }
  ol {
    font-size: 1.2rem;
    padding-left: 18px;

    & li {
      padding-left: 12px;
    }

    &.footnotes {
      font-size: 0.95rem;
      list-style: none;
      padding-left: 0;

      & li {
        padding-left: 0;
      }
    }
  }
  figcaption {
    font-style: italic;
    font-size: 1.1rem;
  }
  a {
    text-decoration: underline;

    &:hover {
      color: $soft-blue;
    }
  }

  .scroll-widget {
    cursor: pointer;
    position: fixed;
    bottom: 10px;
    right: 14px;
  }

  .page-break-before {
    page-break-before: always;
  }

  @media print {
    .scroll-widget {
      display: none;
    }
  }

  ol {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;
  }

  ol > li {
    display: table;
    counter-increment: item;
    margin-bottom: 0.6em;
  }

  ol > li:before {
    content: counters(item, '.') '. ';
    display: table-cell;
    padding-right: 0.6em;
  }

  ol.footnotes > li:before {
    content: '';
  }

  li ol > li {
    margin: 0;
  }

  li ol > li:before {
    content: counters(item, '.') '. ';
  }
}
