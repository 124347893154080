.signup-page {
  // background: lighten($light-blue, 2%);
  //background: rgb(94, 126, 191);
  //background: linear-gradient(90deg, rgba(94, 126, 191, 1) 0%, rgba(79, 99, 146, 1) 100%);
  height: 100vh;

  .bg-white, .bg-black {
    width: 100%;

    @media (min-width: 768px) {
      width: 400px;
    }
  }
}

.timeline-container {
  width: 400px;
  margin: 0 auto;

  .timeline-tick {
    background: $trans-blue;
    border-radius: 6px;
    height: 6px;
    overflow: hidden;
    position: relative;

    &::before {
      background: $gold;
      border-radius: 6px;
      content: '';
      position: absolute;
      right: 100%;
      width: 100%;
      height: 6px;
    }

    &.timeline-tick-completed {
      background: $lilac;
    }

    &.timeline-tick-halfway::before {
      right: 50%;
    }
  }
}

.signup-help {
  background: $lilac;
  position: absolute;
  left: calc(50% + 224px);
  top: 86px;
  width: 300px;
  opacity: 0.75;
  font-size: 18px;
}

.faq-question {
  position: relative;
  border-bottom: 1px solid black;

  .faq-chevron {
    position: absolute;
    bottom: 5px;
    right: 5px;

    &.faq-opened {
      animation: rotate forwards 333ms;
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(180deg);
  }
}
