.dialog-header {
  padding-bottom: 24px;
  position: relative;

  .section-number {
    position: relative;
  }
}

button.close {
  background: transparent;
  border: none;
  color: $primary-blue;
  font-size: 36px;
  font-weight: 100;
  opacity: 1;
  position: absolute;
  right: -20px;
  top: -32px;
  z-index: 1;

  &:focus {
    outline: none;
  }

  &.close-buy {
    top: 10px;
    right: 0;
  }
}
.vesto-input .vesto-currency-input {
  text-align: right;
}

.synchronize-modal {
  .bg-ethereum {
    background-color: #f3f3f3;
    cursor: pointer;
    transition: background-color 330ms;

    &:hover {
      background-color: darken(#f3f3f3, 5%);
    }
  }

  .bg-polygon {
    background-color: #f1edfb;
    cursor: pointer;
    transition: background-color 330ms;

    &:hover {
      background-color: darken(#f1edfb, 5%);
    }
  }

  .bg-trans-blue {
    transition: background-color 330ms;

    &:hover {
      background-color: darken($trans-blue, 5%);
    }
  }
}

.currency-input {
  // text-align: center;

  .input-container {
    display: inline-block;
    position: relative;
    &::before {
      content: '$';
      display: block;
      position: absolute;
      left: -10px;
      font-size: 16px;
    }
    input {
      outline: none;
      font-size: 60px;
      width: 100%;
      text-align: left;
    }
  }
  &.hasvalue {
    input {
      color: $primary-blue;
    }
  }
}
